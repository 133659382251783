.icon-container {
  font-family: MabryBuck;
  text-transform: initial;
}

.icon-social {
  margin-left: 0.5vw;
  width: 1.4vw;
}

.background-icons__container {
  position: fixed;
  width: 100vw;
  height: 100%;

  .background-icon {
    position: fixed;
  }
}
