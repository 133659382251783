.rbg-intro__sections-container {
  display: flex;
  height: 100%;
  width: 90%;
}

.rbg-intro__section--left {
  width: fit-content;
  height: 100%;
  padding: 2vw;
  position: relative;
  margin-right: 5vw;
}

.rbg-intro__section--right {
  width: 100%;
  height: 100%;
  padding: 2vw;
  position: relative;
  flex-grow: 1;
}

.rbg-intro__title {
  width: 13vw;
  margin: 1vw 0;
  display: flex;
  flex-direction: column;
}
@media (max-width: 900px) {
  .rbg-intro__title {
    width: 24vh;
  }
}
@media screen and (min-width: 612px) and (max-width: 1366px) and (min-height: 611px) {
  .rbg-intro__title {
    width: 17vw;
  }
}

.rbg-intro__subtitle {
  display: flex;
  flex-direction: column;
  margin: 1vw 0;

  span {
    padding: 0.6rem 0;
  }
}

.rbg-intro__name {
  font-size: 1.4vw;
  border-top: 0.23vw solid #191918;
}

.rbg-intro__dates {
  font-size: 0.73vw;
  border-bottom: 0.23vw solid #191918;
}
@media (max-width: 900px) {
  .rbg-intro__name {
    font-size: 2.5vh;
  }
  .rbg-intro__dates {
    font-size: 1.2vh;
  }
}
@media screen and (min-width: 612px) and (max-width: 1366px) and (min-height: 611px) {
  .rbg-intro__name {
    font-size: 1.7vw;
  }
  .rbg-intro__dates {
    font-size: 0.88vw;
  }
}

.rbg-intro-slide__text {
  font-size: 1.7vw;
}

@media (max-width: 900px) {
  .rbg-intro-slide__text {
    font-size: 3vh;
  }
}
@media screen and (min-width: 612px) and (max-width: 1366px) and (min-height: 611px) {
  .rbg-intro-slide__text {
    font-size: 2vw;
  }
}
