.mobile__cover-page--portrait {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fffef3;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 12vh 5.7vh 12vh 3vh;

  .mobile__cover-page__banner {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    width: 2.7vh;
    height: 100vh;
  }

  .mobile__cover-page__content {
    font-size: 7vw;
    color: #686865;
  }
}
@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  .mobile__cover-page--portrait {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
