.banner--horizontal {
  display: flex;
  width: 100%;
  height: 1.1vw;
}

.banner-animated__reveal {
  position: absolute;
  top: 0;
  z-index: 80;
  width: 100vw;
  overflow: hidden;
}
.banner-animated {
  height: 2.7vw;
  width: 100vw;

  .banner__color {
    transform: scaleX(0);
    transform-origin: left center;
  }
}

.banner--vertical {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 2.7vw;
}

.banner__color {
  width: 100%;
  height: 100%;
}

.banner__color--pink {
  background-color: rgb(234, 68, 247);
}

.banner__color--yellow {
  background-color: rgb(255, 253, 88);
}
.banner__color--orange {
  background-color: rgb(240, 145, 59);
}
.banner__color--turquoise {
  background-color: rgb(115, 244, 246);
}
.banner__color--red {
  background-color: #ff0000;
}
.banner__color--green {
  background-color: #00ff00;
}
.banner__color--blue {
  background-color: #0000ff;
}
.banner__color--purple {
  background-color: rgb(123, 50, 238);
}

.banner--vertical {
  position: relative;
  z-index: 70;
  transition: transform 0.5s linear;
}
.banner--vertical.c-outro {
  transform: translateY(calc(100vh + 2.7vw));
}

.banner-animated__reveal {
  transition: transform 0.5s linear;
}
.banner-animated__reveal.c-outro {
  transform: translateY(calc(100vh + 2.7vw));
}
