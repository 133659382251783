.rbg-narrative {
  position: relative;
  top: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  height: 100%;
  max-height: 100%;
  width: 100%;
  min-width: 100%;
}
.rbg-narrative.c-footer {
  transform: translateY(-100%);
}

.rbg__sidebar-resources {
  width: 87vh;
  font-size: 1.5vw;
  border-top: 0.23vw solid white;
  border-bottom: 0.23vw solid white;
  display: flex;
  justify-content: space-between;
  z-index: 800;
  mix-blend-mode: difference;
  position: fixed;
  top: 50%;
  left: calc(4vw + env(safe-area-inset-left));
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0s;
}
.rbg__sidebar-resources.c-sidebar {
  opacity: 1;
  transition-delay: 0.4s;
}

.rbg__btn-resources {
  position: fixed;
  bottom: 8%;
  font-size: 1.5vw;
  margin-left: calc(
    2vw + env(safe-area-inset-left)
  ); // env for mobile left notch spacing
  left: 0;
  border-bottom: 0.23vw solid white;
  padding-bottom: 0.3vw;
  z-index: 800;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.4s;
}
.rbg__btn-resources.c-sidebar {
  opacity: 0;
  transition-delay: 0s;
}

.rbg-arrow--right {
  position: fixed;
  bottom: 10%;
  left: 90vw;
  font-size: 9vw;
  z-index: 800;
  mix-blend-mode: difference;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  visibility: visible;
}
.rbg-arrow--right.c-outro {
  opacity: 0;
  visibility: hidden;
}

button:hover {
  opacity: 0.3;
}

.rbg-slide {
  width: 85vw;
  height: 100%;
  max-height: 100%;
  white-space: normal;
  position: relative;
  z-index: 2;
}

.rbg-slide-quote {
  width: 90vw;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: normal;
}

.rbg-slide-quote__section--left {
  width: 55%;
  height: fit-content;
}

.rbg-slide__sections-container--flex {
  display: flex;
  height: 100%;
  max-height: 100%;
}

.rbg-slide__text {
  font-size: 1.5vw;
}
@media (max-width: 900px) {
  .rbg__sidebar-resources {
    width: 74vh;
    max-width: 300px;
  }
  .rbg-slide__text {
    font-size: 3vh;
  }
}
@media screen and (min-width: 612px) and (max-width: 1366px) and (min-height: 611px) {
  .rbg-slide__text {
    font-size: 1.8vw;
  }
}

.rbg-slide__title {
  font-size: 3vw;

  flex-grow: 1;

  p {
    padding: 1vw 0;
  }
}
@media (max-width: 900px) {
  .rbg-slide__title {
    font-size: 4.7vh;
  }
}
@media screen and (min-width: 612px) and (max-width: 1366px) and (min-height: 611px) {
  .rbg-slide__title {
    font-size: 2.7vw;
  }
}

.rbg-slide__subtitle {
  font-size: 2.5vw;
  position: relative;
  top: 30%;
}

.rbg-slide__arrow {
  font-size: 13vw;
  color: #333331;
  margin-right: 10vw;

  p {
    line-height: 0.5;
  }
}
@media (max-width: 900px) {
  .rbg-slide__arrow {
    font-size: 21.5vh;
  }
}

.rbg__quote-from {
  display: flex;
  align-items: center;
  padding: 2vw 0;
}

.quote-from__line {
  width: 126px;
  height: 0.23vw;
  background-color: white;
}

.rbg-slide__image--w-banner {
  padding: 2vw 0;
  height: 100%;

  .rbg-slide__image {
    height: calc(100% - 7.1vw);
  }

  > div:first-child {
    margin-bottom: 2vw;
  }
}

.rbg-slide__image {
  height: 100%;
}

.rbg-slide__section--left {
  height: 100%;
  margin-right: 2vw;
  max-width: 70%;
}

.rbg-slide__section--right {
  position: relative;
  height: 100%;
  white-space: nowrap;
  min-width: 15%;
}

.rbg-slide__artist {
  font-size: 1.6vw;
}
.rbg-slide__artist-title {
  color: #686865;
  font-family: Mabry;
  font-weight: 700;
}

.rbg-slide__sections-container--block {
  display: block;
  width: 90%;
}

.rbg-slide__section--top {
  margin: 2vw 0;
  display: inline-block;
  width: 100%;
}

.rbg-slide__section--bottom {
  // margin-top: 2vw;
  display: inline-block;
  width: 100%;
}

.rbg-slide__header {
  width: fit-content;
}
.rbg-slide__content {
  width: 55%;
  float: right;
  border-top: 0.23vw solid #191918;
  border-bottom: 0.23vw solid #191918;
  padding: 0.7vw 0;
}

.rbg-slide__header-text {
  display: flex;
}
