@font-face {
  font-family: Mabry;
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/MabryMediumProWeb.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: MabryBuck;
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/MabryBuckIconsLight.woff2") format("woff2");
  speak: none;
  font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-decoration: none !important;
  text-rendering: optimizeLegibility;
  text-transform: none !important;
  font-display: swap;
}

html,
body {
  position: fixed;
  background-color: #191918;
  color: #cccbc4;
  font-family: century-old-style-std, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  width: 100%;
  margin: 0;
}

#root,
.content {
  position: fixed;
  height: 100%;
  max-height: 100%;
}

.font-mabry-medium {
  font-family: Mabry, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.font--white {
  color: #fffef3;
}

.o-scroll {
  background-color: #191918;
  overflow: hidden;
  transition: background-color 0.44s ease-in-out;
}

.o-scroll.c-outro {
  background-color: #fffef3;
}

.text-indent {
  text-indent: 4%;
}

.padding-top--small {
  padding-top: 1.5vw;
}
.padding-top--medium {
  padding-top: 3vw;
}
.margin-bottom--large {
  margin-bottom: 5vw;
}
@media (max-width: 900px) {
  .margin-bottom--large {
    margin-bottom: 6.5vh;
  }
}
