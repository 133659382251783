.rbg-outro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rbg-outro__section {
  height: 100%;
  padding: 2vw;
  margin-left: 5%;
}

.rbg-outro__section--center {
  width: 50%;
  text-align: center;
}

.rbg-outro__title {
  font-size: 8vw;
}
.rbg-outro__title.c-outro {
  color: #191918;
}

.background-icon.outro {
  top: -2.7vw;
  -webkit-mask-image: url(../../images/svg/Outro.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top;
  -webkit-mask-size: cover;
  mask-image: url(../../images/svg/Outro.svg);
  mask-repeat: no-repeat;
  mask-position: left top;
  mask-size: cover;
  background-color: #333331;
  width: 100vw;
  height: 100vh;

  .banner--horizontal {
    height: 0vh;
    width: 100vw;
    position: absolute;
    transition: height 0.44s linear;
  }
  .banner--horizontal.c-outro {
    height: 100vh;
  }
}
